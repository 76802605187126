import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const BookDrawing = React.memo<IconProps>(props => {
  return (
    <Icon
      width={'106px'}
      height={'69px'}
      viewBox="0 0 106 69"
      fill="none"
      {...props}
    >
      <path
        d="M46.69 66.215c-12.78-12.47-26.405-26.009-40.025-38.62C3.915 25.051.978 22.024 1 18.258c.028-4.403 4.127-7.685 8.208-9.25 3.946-1.507 8.157-2.07 12.328-2.628L62.124 1C76.499 14.442 94.504 31.719 105 43.819 90.986 47.1 76.391 51.35 62.383 54.627c-5.806 1.36-12.323 3.266-15.006 8.63-.535 1.058-.84 2.458-.062 3.35.62.712 1.691.803 2.61.837a201.17 201.17 0 0 0 53.254-5.302c.705-5.472.406-11.497.231-17.015M11.637 25.08l3.788 3.868M32.523 45.076l4.177 3.635"
        stroke="#2C2B2A"
        strokeWidth={props.strokeWidth ?? 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.22 16.882a61.92 61.92 0 0 1 7.43-1.843M43.318 22.554a75.091 75.091 0 0 1 13.53-4.033M55.004 61.783c14.888-5.068 33-8.743 48.39-11.946M69.063 61.784c8.693-.83 25.807-3.106 34.33-4.995"
        stroke="#2C2B2A"
        strokeWidth={props.strokeWidth ?? 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export default BookDrawing;
