import * as React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg
      width="56"
      height="24"
      viewBox="0 0 56 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.437635 0.378522C0.133633 0.743311 -0.0198073 1.2103 0.00859017 1.68431V22.3158C-0.0198073 22.7898 0.133633 23.2568 0.437635 23.6216L0.502924 23.6868L12.0591 12.1399V11.8695L0.502924 0.313232L0.437635 0.378522Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M15.8839 15.992L12.0598 12.1399V11.8695L15.8839 8.00806L15.9679 8.05469L20.5474 10.6569C21.8532 11.3938 21.8532 12.6063 20.5474 13.3525L15.9865 15.9454L15.8839 15.992Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M15.9955 15.936L12.0595 12L0.437988 23.6215C0.987089 24.1042 1.802 24.1277 2.37802 23.6775L16.0049 15.936"
        fill="url(#paint2_linear)"
      />
      <path
        d="M15.9961 8.06396L2.36923 0.322505C1.79322 -0.127736 0.9783 -0.104229 0.429199 0.378467L12.06 12L15.9961 8.06396Z"
        fill="url(#paint3_linear)"
      />
      <path
        opacity="0.2"
        d="M15.8836 15.8521L2.36869 23.5469C1.81563 23.9604 1.05634 23.9604 0.503278 23.5469L0.437988 23.6122L0.503278 23.6775C1.0553 24.0941 1.81667 24.0941 2.36869 23.6775L15.9955 15.936L15.8836 15.8521Z"
        fill="black"
      />
      <path
        opacity="0.12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8834 15.8521L20.5469 13.2125C21.0288 12.9746 21.3733 12.5268 21.4796 12C21.4315 12.5824 21.0758 13.0946 20.5469 13.3431L15.9673 15.936L15.8834 15.8521ZM0.00753768 22.3667C0.0229394 22.7771 0.173542 23.1729 0.438611 23.491L0.503901 23.5656L0.438611 23.6309C0.14352 23.2768 -0.00970719 22.8264 0.00753768 22.3667ZM0.00753768 22.3667C0.00805836 22.3529 0.00873445 22.339 0.00956674 22.3251V22.1852C0.00593 22.2459 0.00527555 22.3065 0.00753768 22.3667Z"
        fill="black"
      />
      <path
        opacity="0.25"
        d="M2.36907 0.453278L20.5475 10.7877C21.0294 11.0255 21.3739 11.4733 21.4802 12.0002C21.4322 11.4178 21.0764 10.9055 20.5475 10.6571L2.36907 0.322699C1.06328 -0.414138 0 0.201448 0 1.69378V1.83368C0.0279812 0.332026 1.07261 -0.283559 2.36907 0.453278Z"
        fill="white"
      />
      <path
        d="M51.1494 0H36.8473C34.1685 0 32 2.16879 32 4.84787V19.1548C32 21.8306 34.1685 24 36.8473 24H51.1527C53.8282 24 56 21.8312 56 19.1521V4.84787C55.9967 2.16944 53.8282 0 51.1494 0Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M43.8968 5.51341L44.3827 4.67374C44.6826 4.14903 45.3513 3.97215 45.8767 4.27199C46.4014 4.57182 46.5789 5.24119 46.2784 5.76591L41.5961 13.8707H44.9824C46.0805 13.8707 46.6953 15.1608 46.2186 16.0543H36.2898C35.6836 16.0543 35.1976 15.5684 35.1976 14.9622C35.1976 14.3559 35.6836 13.87 36.2898 13.87H39.0732L42.637 7.6938L41.5244 5.76196C41.2246 5.23725 41.4015 4.5738 41.9262 4.26804C42.4509 3.96821 43.1144 4.14508 43.4201 4.6698L43.8968 5.51341ZM39.6853 17.1787L38.6352 18.9994C38.3354 19.5242 37.666 19.701 37.1413 19.4012C36.6166 19.1014 36.4397 18.4326 36.7396 17.9073L37.5194 16.5574C38.4012 16.2851 39.1179 16.4949 39.6853 17.1787ZM48.7258 13.8766H51.5663C52.1726 13.8766 52.6585 14.3625 52.6585 14.9688C52.6585 15.575 52.1726 16.0609 51.5663 16.0609H49.9882L51.0528 17.9086C51.3526 18.4333 51.1757 19.0968 50.651 19.4025C50.1263 19.7023 49.4629 19.5255 49.1571 19.0008C47.3633 15.89 46.0167 13.5623 45.1225 12.0118C44.2078 10.4337 44.8614 8.8504 45.5065 8.31319C46.2245 9.54212 47.295 11.399 48.7258 13.8766Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="6.99757"
          y1="-13.7044"
          x2="-11.5068"
          y2="-8.7831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.01" stopColor="#00A1FF" />
          <stop offset="0.26" stopColor="#00BEFF" />
          <stop offset="0.51" stopColor="#00D2FF" />
          <stop offset="0.76" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="22.2543"
          y1="2.48614"
          x2="-0.307865"
          y2="2.48614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.41" stopColor="#FFBD00" />
          <stop offset="0.78" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="0.0965159"
          y1="5.96309"
          x2="-14.7675"
          y2="30.9658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="-8.63669"
          y1="-2.83377"
          x2="-2.00208"
          y2="8.33116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.07" stopColor="#2DA771" />
          <stop offset="0.48" stopColor="#15CF74" />
          <stop offset="0.8" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="44.0017"
          y1="0.00211092"
          x2="44.0017"
          y2="24.0021"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ED4FC" />
          <stop offset="1" stopColor="#0C57EF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Installs = React.memo(SvgComponent);
