import { useColorMode } from 'deepstash-ui';
import { color } from 'deepstash-ui';
import React, { useEffect, useRef } from 'react';
import styles from './Bookmark.module.sass';

interface OwnProps {
  saved: boolean;
}

const Bookmark: React.FC<OwnProps> = ({ saved }) => {
  const prevSavedRef = useRef<boolean>();
  const { colorMode } = useColorMode();

  useEffect(() => {
    prevSavedRef.current = saved;
  });

  const prevSaved = prevSavedRef.current;
  const didChange = prevSaved !== saved;
  return (
    <div
      className={!didChange ? '' : saved ? styles.animation : styles.animation2}
      style={{
        cursor: 'pointer',
        position: 'relative',
        top: '7.5px',
        left: '10px',
      }}
    >
      <ul className={styles.bursts}>
        {[...Array(8)].map((_, i) => (
          <li className={styles[`deg${i * 45}`]} key={i} />
        ))}
      </ul>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        width="40"
        height="40"
        preserveAspectRatio="xMidYMid slice"
      >
        <g opacity="1" transform="matrix(1,0,0,1,0,0)">
          <path
            fillRule="evenodd"
            fill={color[colorMode].secondary.default}
            fillOpacity="1"
            d=" M5.0526299476623535,3 C5.0526299476623535,3 4.368420124053955,3 3.6842100620269775,3.708329916000366 C3,4.416669845581055 3,5.125 3,5.125 C3,5.125 3,20 3,20 C3,20 9.5,16.421100616455078 9.5,16.421100616455078 C9.5,16.421100616455078 16,20 16,20 C16,20 16,5.833330154418945 16,5.833330154418945 C16,5.833330154418945 16,4.416669845581055 15.315799713134766,3.708329916000366 C14.631600379943848,3 13.947400093078613,3 13.947400093078613,3 C13.947400093078613,3 5.0526299476623535,3 5.0526299476623535,3z M3,0 C3,0 2,0 1,1.0416699647903442 C0,2.083329916000366 0,3.125 0,3.125 C0,3.125 0,25 0,25 C0,25 9.5,19.736799240112305 9.5,19.736799240112305 C9.5,19.736799240112305 19,25 19,25 C19,25 19,4.166669845581055 19,4.166669845581055 C19,4.166669845581055 19,2.083329916000366 18,1.0416699647903442 C17,0 16,0 16,0 C16,0 3,0 3,0z"
          />
          <path
            strokeLinecap="square"
            strokeLinejoin="miter"
            fillOpacity="1"
            fill={saved ? color[colorMode].secondary.default : 'transparent'}
            strokeMiterlimit="1"
            stroke="rgb(0,0,0)"
            strokeOpacity="0"
            strokeWidth="0"
            d=" M5.0526299476623535,3 C5.0526299476623535,3 4.368420124053955,3 3.6842100620269775,3.708329916000366 C3,4.416669845581055 3,5.125 3,5.125 C3,5.125 3,20 3,20 C3,20 9.5,16.421100616455078 9.5,16.421100616455078 C9.5,16.421100616455078 16,20 16,20 C16,20 16,5.833330154418945 16,5.833330154418945 C16,5.833330154418945 16,4.416669845581055 15.315799713134766,3.708329916000366 C14.631600379943848,3 13.947400093078613,3 13.947400093078613,3 C13.947400093078613,3 5.0526299476623535,3 5.0526299476623535,3z M3,0 C3,0 2,0 1,1.0416699647903442 C0,2.083329916000366 0,3.125 0,3.125 C0,3.125 0,25 0,25 C0,25 9.5,19.736799240112305 9.5,19.736799240112305 C9.5,19.736799240112305 19,25 19,25 C19,25 19,4.166669845581055 19,4.166669845581055 C19,4.166669845581055 19,2.083329916000366 18,1.0416699647903442 C17,0 16,0 16,0 C16,0 3,0 3,0z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Bookmark;
