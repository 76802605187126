import * as React from 'react';
import { color, Icon, IconProps, useColorMode } from 'deepstash-ui';

export const LightBulbDrawing = React.memo<IconProps>(props => {
  const { colorMode } = useColorMode();

  return (
    <Icon
      width={props.w ?? props.width ?? '47px'}
      height={props.h ?? props.height ?? '59px'}
      viewBox="0 0 47 59"
      fill="none"
      {...props}
    >
      <path
        d="m21.387 39.34 1.59 7.96a14.745 14.745 0 0 0 1.003 3.51 4.946 4.946 0 0 0 2.52 2.538c1.099.417 2.339.3 3.518.164 1.37-.157 2.758-.32 4.035-.843 1.277-.523 2.443-1.46 2.93-2.751.519-1.37.204-2.905-.142-4.331-.167-.662-3.047-10.334-3.266-10.217-.005.01-10.024 3.172-12.188 3.97Z"
        stroke={color[colorMode].textSecondary}
        strokeWidth={props.strokeWidth ?? 0.975}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.199 39.057c-2.55-4.487-8.725-4.334-12.52-7.027-4.323-3.077-6.139-8.886-6.293-12.15-.304-6.343 4.72-11.991 10.609-14.374 4.797-1.929 10.395-2.103 15.072.078 4.678 2.181 8.232 6.919 8.479 12.08.157 3.247-.938 6.454-2.544 9.28-1.431 2.517-3.143 5.907-2.599 8.755"
        stroke={color[colorMode].textSecondary}
        strokeWidth={props.strokeWidth ?? 0.975}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.381 34.827a34.92 34.92 0 0 0-6.319-10.836c-1.466-1.71-3.237-3.35-5.46-3.742a1.649 1.649 0 0 0-1.322.199 1.438 1.438 0 0 0-.462 1.006c-.135 1.422.862 2.797 2.168 3.375 1.307.577 2.836.462 4.178-.025 3.88-1.402 6.245-6.048 5.105-10.01a2.272 2.272 0 0 0-.59-1.093c-.795-.69-2.164-.08-2.533.908-.368.987-.022 2.095.464 3.028 1.181 2.274 3.274 4.14 5.775 4.688 2.501.546 5.372-.403 6.76-2.545.463-.625.66-1.407.549-2.177-.266-1.233-1.75-1.935-2.97-1.62-1.219.316-2.114 1.421-2.494 2.624-.38 1.202-.326 2.493-.198 3.744a43.173 43.173 0 0 0 2.542 10.613M25.446 43.922a83.03 83.03 0 0 0 7.862-3.207M25.538 48.81s6.454-3.016 8.276-3.896l.762-.368"
        stroke={color[colorMode].textSecondary}
        strokeWidth={props.strokeWidth ?? 0.975}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export default LightBulbDrawing;
