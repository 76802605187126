import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = (props, key) => {
  return (
    <svg
      key={`star$${key}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5615 2.36597C12.5005 2.23655 12.4006 2.13179 12.2771 2.06792C11.967 1.90751 11.5915 2.04095 11.4385 2.36597L8.68748 8.20802L2.53613 9.14483C2.39985 9.16559 2.2739 9.23285 2.17778 9.3362C1.93639 9.59574 1.94146 10.0112 2.1891 10.2642L6.64026 14.8116L5.58948 21.2327C5.5662 21.3749 5.58832 21.5212 5.6524 21.649C5.81332 21.9698 6.19192 22.0931 6.49802 21.9245L12 18.8929L17.5019 21.9245C17.6238 21.9916 17.7634 22.0148 17.8991 21.9904C18.24 21.9291 18.4689 21.5899 18.4104 21.2327L17.3597 14.8116L21.8108 10.2642C21.9094 10.1635 21.9736 10.0315 21.9934 9.88866C22.0431 9.52997 21.806 9.19695 21.4638 9.14483L15.3124 8.20802L12.5615 2.36597Z"
        fill="#FFB102"
      />
    </svg>
  );
};

export const Star = React.memo(SvgComponent);
