import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="117" height="107" viewBox="0 0 117 107" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5 9.37354C90.9538 -23.1686 172.095 33.7766 58.5 107C-55.0955 33.7837 26.0462 -23.1686 58.5 9.37354Z"
        fill={'#EE9388'}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export const ContentMattersLight = React.memo(SvgComponent);
