import { StyleProps, Icon } from 'deepstash-ui';
import React from 'react';

export const Spine: React.FC<StyleProps & { spineColor: string }> = ({
  spineColor,
  ...props
}) => {
  return (
    <Icon viewBox="0 0 5 116" {...props}>
      <path
        d="M0 1.99997C0 0.895398 0.895431 -3.05176e-05 2 -3.05176e-05H5V116H2C0.89543 116 0 115.105 0 114V1.99997Z"
        fill={spineColor}
      />
    </Icon>
  );
};
