import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="40" height="35" viewBox="0 0 40 35" fill="none" {...props}>
      <path
        d="M21.6919 2.98793C21.6919 0.617505 21.1989 0 18.7339 0H2.95799C0.492999 0 0 0.687223 0 2.98793V31.8712C0 34.3612 0.611318 34.8591 2.95799 34.8591H21.6318L13.311 28.8833H4.92999V4.97988H16.762V24.8994L21.6919 20.3567V2.98793ZM12.818 32.8672H9.85997V30.8752H12.818V32.8672ZM26.9572 27.9272C26.9572 28.256 27.0865 28.5714 27.3167 28.804C27.5469 29.0365 27.8592 29.1671 28.1847 29.1671C28.5103 29.1671 28.8225 29.0365 29.0527 28.804C29.283 28.5714 29.4123 28.256 29.4123 27.9272C29.4123 27.2399 28.8591 26.6822 28.1798 26.6822C27.5093 26.6822 26.9572 27.2399 26.9572 27.9272ZM29.5306 34.9199C33.5239 34.0325 39.6667 28.7458 39.6667 28.7458C39.6667 28.7458 35.1015 21.5828 28.9883 20.8159C28.6422 20.7751 27.4403 20.7651 27.2125 20.786C21.3064 21.3637 16.5342 28.9729 16.5342 28.9729C16.5342 28.9729 21.7195 34.0833 26.5923 34.9089C27.1642 35.0285 28.939 35.0285 29.5306 34.9199ZM22.2342 27.9162C22.2342 24.8287 24.8678 22.3298 28.1009 22.3298C31.334 22.3298 33.9676 24.8297 33.9676 27.9162C33.9676 31.0147 31.334 33.5146 28.1009 33.5146C24.8678 33.5146 22.2342 31.0157 22.2342 27.9162Z"
        fill="#0A8976"
      />
    </svg>
  );
};

export const Phone = React.memo(SvgComponent);
