import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = (props, key) => {
  return (
    <svg
      key={`eye$${key}`}
      style={{ marginRight: '15px' }}
      width="25"
      height="19"
      viewBox="0 0 25 19"
      fill="none"
      {...props}
    >
      <path
        d="M9.17411 9.34687C9.17411 10.1985 9.51243 11.0153 10.1146 11.6175C10.7169 12.2197 11.5336 12.5581 12.3853 12.5581C13.237 12.5581 14.0538 12.2197 14.656 11.6175C15.2582 11.0153 15.5965 10.1985 15.5965 9.34687C15.5965 8.49521 15.2582 7.67843 14.656 7.07621C14.0538 6.474 13.237 6.13568 12.3853 6.13568C11.5336 6.13568 10.7169 6.474 10.1146 7.07621C9.51243 7.67843 9.17411 8.49521 9.17411 9.34687ZM24.8344 8.60715C22.1164 2.88147 18.0078 0 12.5 0C6.98935 0 2.88361 2.88147 0.165564 8.61002C0.0565418 8.84087 0 9.093 0 9.3483C0 9.60361 0.0565418 9.85574 0.165564 10.0866C2.88361 15.8123 6.99222 18.6937 12.5 18.6937C18.0106 18.6937 22.1164 15.8123 24.8344 10.0837C25.0552 9.61925 25.0552 9.08023 24.8344 8.60715ZM12.3853 14.393C9.59845 14.393 7.33914 12.1337 7.33914 9.34687C7.33914 6.56001 9.59845 4.30071 12.3853 4.30071C15.1722 4.30071 17.4315 6.56001 17.4315 9.34687C17.4315 12.1337 15.1722 14.393 12.3853 14.393Z"
        fill={props.fill}
      />
    </svg>
  );
};

export const Eye = React.memo(SvgComponent);
