import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="121" height="121" viewBox="0 0 121 121" fill="none" {...props}>
      <circle opacity="0.6" cx="60.5" cy="60.5" r="60.5" fill={'#9e8600'} />
    </svg>
  );
};

export const CurateIsCreateDark = React.memo(SvgComponent);
