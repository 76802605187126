import * as React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps & { color?: string }> = props => {
  return (
    <svg
      width={props.width || '22'}
      height={props.height || '22'}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 9L9.5 14L7 11.7273"
        stroke={props.color ?? '#0A8976'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM2.53 11C2.53 15.6779 6.32215 19.47 11 19.47C15.6779 19.47 19.47 15.6779 19.47 11C19.47 6.32215 15.6779 2.53 11 2.53C6.32215 2.53 2.53 6.32215 2.53 11Z"
        fill={props.color ?? '#0A8976'}
      />
    </svg>
  );
};

export const CheckedCircle = React.memo(SvgComponent);
