import { Icon } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';
import { AI_COLOR } from 'utils/constants';

const SvgComponent: React.FC<SVGProps & { color?: string }> = props => {
  return (
    <Icon
      transition="0.3s all ease-out"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.80729 5.85499C9.92817 5.3576 10.6355 5.3576 10.7564 5.85499L12.0609 11.2225C12.1038 11.3987 12.2408 11.5367 12.4167 11.5809L17.7302 12.9133C18.223 13.0368 18.223 13.7371 17.7302 13.8607L12.4167 15.1931C12.2408 15.2372 12.1038 15.3752 12.0609 15.5515L10.7564 20.919C10.6355 21.4164 9.92817 21.4164 9.80729 20.919L8.50278 15.5515C8.45994 15.3752 8.32295 15.2372 8.147 15.1931L2.83347 13.8607C2.34068 13.7371 2.34068 13.0368 2.83348 12.9133L8.147 11.5809C8.32295 11.5367 8.45994 11.3987 8.50278 11.2225L9.80729 5.85499Z"
        fill={props.color ?? AI_COLOR}
      />
      <path
        d="M18.3628 2.39564C18.4448 2.0665 18.9126 2.0665 18.9946 2.39564L19.4978 4.41449C19.527 4.53183 19.6189 4.6233 19.7363 4.65203L21.7624 5.1475C22.0934 5.22844 22.0934 5.69909 21.7624 5.78003L19.7363 6.2755C19.6189 6.30423 19.527 6.39569 19.4978 6.51304L18.9946 8.53189C18.9126 8.86103 18.4448 8.86103 18.3628 8.53189L17.8597 6.51304C17.8304 6.39569 17.7386 6.30423 17.6211 6.2755L15.595 5.78003C15.264 5.69909 15.264 5.22844 15.595 5.1475L17.6211 4.65203C17.7386 4.6233 17.8304 4.53183 17.8597 4.41449L18.3628 2.39564Z"
        fill={props.color ?? AI_COLOR}
      />
    </Icon>
  );
};

export const StashyAISvg = React.memo(SvgComponent);
