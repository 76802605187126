import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = (props, key) => {
  return (
    <svg
      key={`lightBulb$${key}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 2C16.1421 2 19.5 5.24616 19.5 9.25051C19.5 11.3467 18.5683 13.2705 16.7451 14.9928C16.6655 15.068 16.6039 15.1586 16.564 15.2581L16.5314 15.3604L16.3786 16H7.62294L7.47033 15.3607C7.43694 15.2208 7.3627 15.0931 7.25644 14.9928C5.51913 13.3524 4.59081 11.5294 4.50634 9.54878L4.5 9.25051L4.50401 9.01118C4.63463 5.11761 7.94071 2 12 2Z"
        fill={props.fill}
      />
      <path
        d="M7.86165 17H16.1396L15.6616 19H8.33907L7.86165 17Z"
        fill={props.fill}
      />
      <path
        d="M8.57778 20L8.63885 20.2558L8.68582 20.4231C8.98749 21.3549 9.88319 22 10.9068 22H13.0935L13.2727 21.9934C14.2795 21.9188 15.131 21.2204 15.3613 20.2564L15.4226 20H8.57778Z"
        fill={props.fill}
      />
    </svg>
  );
};

export const LightBulb = React.memo(SvgComponent);
