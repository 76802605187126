import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="23" height="25" viewBox="0 0 23 25" fill="none" {...props}>
      <path
        d="M14.9527 11.4427L3.95251 0.63489L17.9483 8.50752L14.9527 11.4427ZM1.08158 0C0.43363 0.332096 0 0.937683 0 1.72397V23.276C0 24.0623 0.43363 24.6679 1.08158 25L13.8712 12.4976L1.08158 0ZM22.2746 11.0178L19.3389 9.35241L16.0642 12.5024L19.3389 15.6525L22.3344 13.9871C23.2316 13.2887 23.2316 11.7162 22.2746 11.0178ZM3.95251 24.37L17.9483 16.4974L14.9527 13.5622L3.95251 24.37Z"
        fill="#D44C3A"
      />
    </svg>
  );
};

export const GooglePlay = React.memo(SvgComponent);
