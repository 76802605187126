import { Icon, StyleProps } from 'deepstash-ui';
import React from 'react';

export const ShieldCheckmarkSvg: React.FC<StyleProps> = props => {
  return (
    <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill={props.fill ?? '#3B3F44'}
        d="M3 5.75A.75.75 0 0 1 3.75 5c2.663 0 5.258-.943 7.8-2.85a.75.75 0 0 1 .9 0C14.992 4.057 17.587 5 20.25 5a.75.75 0 0 1 .75.75V11c0 5.001-2.958 8.676-8.725 10.948a.75.75 0 0 1-.55 0C5.958 19.676 3 16 3 11V5.75Zm1.5.728V11c0 4.256 2.453 7.379 7.5 9.442 5.047-2.063 7.5-5.186 7.5-9.442V6.478c-2.577-.152-5.08-1.09-7.5-2.8-2.42 1.71-4.923 2.648-7.5 2.8Z"
      />
      <path
        fill={props.fill ?? '#3B3F44'}
        d="M15.798 9.214a.718.718 0 0 1 0 1.035l-4.511 4.394a.762.762 0 0 1-1.063 0L8.22 12.69a.718.718 0 0 1 0-1.034.766.766 0 0 1 1.063 0l1.472 1.434 3.98-3.877a.766.766 0 0 1 1.063 0Z"
      />
    </Icon>
  );
};
