import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = (props, key) => {
  return (
    <svg
      key={`lightBulb$${key}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.53353 3.63333C4.53353 3.28355 4.81335 3 5.15853 3H8.94368C10.1218 3 11.2254 3.5129 12 4.38092C12.7746 3.5129 13.8782 3 15.0563 3H18.8415C19.1866 3 19.4665 3.28355 19.4665 3.63333V4.26667H21.375C21.7202 4.26667 22 4.55022 22 4.9V21.3667C22 21.7164 21.7202 22 21.375 22H2.625C2.27982 22 2 21.7164 2 21.3667V4.9C2 4.55022 2.27982 4.26667 2.625 4.26667H4.53353V3.63333ZM18.2165 4.85461C18.2154 4.8696 18.2149 4.88474 18.2149 4.9C18.2149 4.91526 18.2154 4.9304 18.2165 4.94539V18.1255H15.2753C14.2945 18.1255 13.3664 18.4687 12.625 19.0671V5.63326C13.1578 4.78178 14.0735 4.26667 15.0563 4.26667H18.2165V4.85461ZM19.4665 5.53333H20.75V20.7333H12.7838C13.3482 19.8984 14.2766 19.3922 15.2753 19.3922H18.8415C19.1866 19.3922 19.4665 19.1086 19.4665 18.7588V5.53333ZM8.72471 19.3922C9.72336 19.3922 10.6518 19.8984 11.2162 20.7333H3.25V5.53333H4.53353V18.7588C4.53353 19.1086 4.81335 19.3922 5.15853 19.3922H8.72471ZM5.78353 18.1255V4.94539C5.78458 4.9304 5.78511 4.91526 5.78511 4.9C5.78511 4.88474 5.78458 4.8696 5.78353 4.85461V4.26667H8.94368C9.92649 4.26667 10.8422 4.78178 11.375 5.63326V19.0671C10.6336 18.4687 9.70551 18.1255 8.72471 18.1255H5.78353Z"
        fill={props.fill}
      />
    </svg>
  );
};

export const OpenBook = React.memo(SvgComponent);

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
></svg>;
