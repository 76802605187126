import * as React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg
      width={props.width || '240'}
      height={props.height || '217'}
      viewBox="0 0 240 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.4637 54.9993C55.3369 42.684 42.593 35.8394 34.2093 38.6393C25.8256 41.4392 19.7725 54.5616 23.8993 66.8769C27.7191 78.2762 39.2938 84.8571 47.2563 83.6827C47.1989 83.7824 47.1611 83.8921 47.1449 84.006L46.9015 86.0333C46.8829 86.1878 46.9042 86.3445 46.9634 86.4885C47.0226 86.6325 47.1176 86.7591 47.2396 86.8561C47.3615 86.9531 47.5062 87.0174 47.66 87.0428C47.8138 87.0683 47.9716 87.0541 48.1184 87.0015L52.22 85.5333C52.3668 85.4808 52.4976 85.3917 52.6002 85.2744C52.7028 85.1572 52.7736 85.0157 52.806 84.8635C52.8384 84.7112 52.8313 84.5532 52.7853 84.4045C52.7394 84.2557 52.6561 84.1212 52.5434 84.0136L51.0649 82.6027C51.0123 82.5547 50.9543 82.5131 50.8919 82.4786C57.9938 78.6578 63.2919 66.4234 59.4637 54.9993Z"
        fill="white"
      />
      <path
        d="M44.5241 45.9388C47.9512 47.7046 50.5685 50.7165 51.8356 54.3529C53.1027 57.9893 52.923 61.9726 51.3336 65.4806C50.9787 66.2872 52.2838 66.7113 52.6364 65.91C54.3052 62.1144 54.475 57.8292 53.1116 53.9139C51.7482 49.9985 48.9526 46.7425 45.2852 44.7988C44.5112 44.3893 43.7443 45.5263 44.5241 45.9388Z"
        fill="white"
      />
      <path
        d="M226.542 37.0479C222.415 24.7326 209.671 17.8879 201.288 20.6879C192.904 23.4878 186.851 36.6101 190.978 48.9255C194.797 60.3248 206.372 66.9056 214.335 65.7313C214.277 65.8309 214.239 65.9407 214.223 66.0545L213.98 68.0819C213.961 68.2364 213.983 68.3931 214.042 68.5371C214.101 68.6811 214.196 68.8076 214.318 68.9046C214.44 69.0016 214.585 69.0659 214.738 69.0914C214.892 69.1168 215.05 69.1026 215.197 69.0501L219.298 67.5819C219.445 67.5293 219.576 67.4402 219.679 67.323C219.781 67.2058 219.852 67.0643 219.884 66.912C219.917 66.7598 219.91 66.6018 219.864 66.453C219.818 66.3043 219.734 66.1698 219.622 66.0622L218.143 64.6513C218.091 64.6033 218.033 64.5616 217.97 64.5272C225.072 60.7064 230.37 48.472 226.542 37.0479Z"
        fill="white"
      />
      <path
        d="M211.602 27.9874C215.03 29.7532 217.647 32.7651 218.914 36.4015C220.181 40.0379 220.001 44.0212 218.412 47.5292C218.057 48.3358 219.362 48.7599 219.715 47.9586C221.384 44.163 221.553 39.8778 220.19 35.9625C218.827 32.0471 216.031 28.7911 212.364 26.8474C211.59 26.4379 210.823 27.5749 211.602 27.9874Z"
        fill="white"
      />
      <path
        d="M129.517 17.2266C125.39 4.91131 112.646 -1.93335 104.262 0.866576C95.8786 3.6665 89.8255 16.7889 93.9522 29.1042C97.7721 40.5035 109.347 47.0843 117.309 45.91C117.252 46.0097 117.214 46.1194 117.198 46.2333L116.954 48.2606C116.936 48.4151 116.957 48.5718 117.016 48.7158C117.076 48.8598 117.171 48.9863 117.293 49.0833C117.414 49.1804 117.559 49.2446 117.713 49.2701C117.867 49.2956 118.025 49.2814 118.171 49.2288L122.273 47.7606C122.42 47.708 122.551 47.6189 122.653 47.5017C122.756 47.3845 122.827 47.243 122.859 47.0907C122.891 46.9385 122.884 46.7805 122.838 46.6318C122.792 46.483 122.709 46.3485 122.596 46.2409L121.118 44.83C121.065 44.782 121.007 44.7403 120.945 44.7059C128.047 40.8851 133.345 28.6507 129.517 17.2266Z"
        fill="white"
      />
      <path
        d="M114.577 8.16612C118.004 9.93187 120.621 12.9438 121.889 16.5802C123.156 20.2166 122.976 24.1999 121.387 27.7079C121.032 28.5145 122.337 28.9386 122.689 28.1373C124.358 24.3417 124.528 20.0565 123.165 16.1412C121.801 12.2258 119.006 8.96982 115.338 7.02609C114.564 6.6166 113.797 7.75359 114.577 8.16612Z"
        fill="white"
      />
      <path
        d="M108.819 76.4856C109.362 76.4856 109.802 76.0462 109.802 75.5041C109.802 74.962 109.362 74.5226 108.819 74.5226C108.276 74.5226 107.836 74.962 107.836 75.5041C107.836 76.0462 108.276 76.4856 108.819 76.4856Z"
        fill="#828595"
      />
      <path
        d="M106.724 115.859C107.796 115.859 108.666 114.991 108.666 113.92C108.666 112.849 107.796 111.981 106.724 111.981C105.651 111.981 104.781 112.849 104.781 113.92C104.781 114.991 105.651 115.859 106.724 115.859Z"
        fill="#828595"
      />
      <path
        d="M74.2087 108.393C74.8285 108.393 75.331 107.891 75.331 107.272C75.331 106.653 74.8285 106.152 74.2087 106.152C73.5889 106.152 73.0864 106.653 73.0864 107.272C73.0864 107.891 73.5889 108.393 74.2087 108.393Z"
        fill="#828595"
      />
      <path
        d="M91.2586 90.0472C91.9298 90.0472 92.474 89.504 92.474 88.8339C92.474 88.1638 91.9298 87.6205 91.2586 87.6205C90.5874 87.6205 90.0432 88.1638 90.0432 88.8339C90.0432 89.504 90.5874 90.0472 91.2586 90.0472Z"
        fill="#828595"
      />
      <path
        d="M151.064 161.085C151.607 161.085 152.047 160.646 152.047 160.104C152.047 159.562 151.607 159.122 151.064 159.122C150.521 159.122 150.081 159.562 150.081 160.104C150.081 160.646 150.521 161.085 151.064 161.085Z"
        fill="#828595"
      />
      <path
        d="M118.866 140.903C119.938 140.903 120.808 140.035 120.808 138.964C120.808 137.893 119.938 137.025 118.866 137.025C117.793 137.025 116.923 137.893 116.923 138.964C116.923 140.035 117.793 140.903 118.866 140.903Z"
        fill="#828595"
      />
      <path
        d="M140.982 115.379C141.602 115.379 142.104 114.877 142.104 114.258C142.104 113.639 141.602 113.138 140.982 113.138C140.362 113.138 139.86 113.639 139.86 114.258C139.86 114.877 140.362 115.379 140.982 115.379Z"
        fill="#828595"
      />
      <path
        d="M148.363 139.459C149.034 139.459 149.578 138.916 149.578 138.246C149.578 137.576 149.034 137.033 148.363 137.033C147.692 137.033 147.147 137.576 147.147 138.246C147.147 138.916 147.692 139.459 148.363 139.459Z"
        fill="#828595"
      />
      <path
        d="M213.826 201.951C214.898 201.055 213.449 197.563 210.589 194.153C207.729 190.743 204.541 188.705 203.468 189.601C202.396 190.498 203.845 193.989 206.705 197.399C209.565 200.809 212.753 202.847 213.826 201.951Z"
        fill="#424761"
      />
      <path
        d="M191.487 209.086C200.397 209.086 207.621 201.875 207.621 192.979C207.621 184.084 200.397 176.873 191.487 176.873C182.577 176.873 175.353 184.084 175.353 192.979C175.353 201.875 182.577 209.086 191.487 209.086Z"
        fill="#424761"
      />
      <path
        d="M198.316 205.62H193.414V214.387H198.316V205.62Z"
        fill="#424761"
      />
      <path
        d="M188.513 205.62H183.612V214.387H188.513V205.62Z"
        fill="#424761"
      />
      <path
        d="M194.231 216.018C196.487 216.018 198.316 215.333 198.316 214.489C198.316 213.644 196.487 212.96 194.231 212.96C191.975 212.96 190.147 213.644 190.147 214.489C190.147 215.333 191.975 216.018 194.231 216.018Z"
        fill="#424761"
      />
      <path
        d="M184.428 215.814C186.684 215.814 188.513 215.13 188.513 214.285C188.513 213.441 186.684 212.756 184.428 212.756C182.173 212.756 180.344 213.441 180.344 214.285C180.344 215.13 182.173 215.814 184.428 215.814Z"
        fill="#424761"
      />
      <path
        d="M186.421 172.733C187.862 166.941 194.221 163.532 200.624 165.12C207.028 166.707 211.052 172.689 209.611 178.481C208.17 184.273 203.391 184.292 196.987 182.705C190.583 181.117 184.98 178.525 186.421 172.733Z"
        fill="#FFB102"
      />
      <path
        d="M180.604 188.957C181.203 187.695 178.426 185.128 174.4 183.222C170.375 181.316 166.626 180.793 166.026 182.055C165.427 183.316 168.204 185.884 172.23 187.79C176.255 189.696 180.004 190.219 180.604 188.957Z"
        fill="#424761"
      />
      <path
        d="M189.075 194.951C192.046 194.951 194.454 192.547 194.454 189.581C194.454 186.615 192.046 184.211 189.075 184.211C186.104 184.211 183.696 186.615 183.696 189.581C183.696 192.547 186.104 194.951 189.075 194.951Z"
        fill="white"
      />
      <path
        d="M186.864 189.446C187.855 189.446 188.657 188.645 188.657 187.656C188.657 186.667 187.855 185.866 186.864 185.866C185.874 185.866 185.071 186.667 185.071 187.656C185.071 188.645 185.874 189.446 186.864 189.446Z"
        fill="#424761"
      />
      <path
        d="M192.449 200.769C192.534 201.231 192.526 201.706 192.427 202.165C192.327 202.625 192.138 203.06 191.87 203.447C191.602 203.834 191.261 204.164 190.865 204.419C190.469 204.673 190.027 204.848 189.564 204.932C189.1 205.016 188.625 205.008 188.165 204.909C187.704 204.81 187.268 204.621 186.881 204.354C186.494 204.086 186.163 203.745 185.908 203.35C185.652 202.955 185.478 202.514 185.393 202.051L185.392 202.044C185.039 200.099 186.546 199.41 188.495 199.058C190.444 198.706 192.097 198.823 192.449 200.769Z"
        fill="white"
      />
      <path
        d="M58.8448 181.719C60.4354 177.564 60.6658 173.792 59.3594 173.294C58.0529 172.795 55.7044 175.759 54.1137 179.914C52.5231 184.068 52.2927 187.841 53.5992 188.339C54.9056 188.838 57.2542 185.874 58.8448 181.719Z"
        fill="#424761"
      />
      <path
        d="M41.5669 209.086C50.4773 209.086 57.7006 201.875 57.7006 192.979C57.7006 184.084 50.4773 176.873 41.5669 176.873C32.6565 176.873 25.4332 184.084 25.4332 192.979C25.4332 201.875 32.6565 209.086 41.5669 209.086Z"
        fill="#424761"
      />
      <path d="M39.1163 205.62H34.215V214.387H39.1163V205.62Z" fill="#424761" />
      <path
        d="M48.9189 205.62H44.0176V214.387H48.9189V205.62Z"
        fill="#424761"
      />
      <path
        d="M38.2994 216.018C40.5552 216.018 42.3839 215.333 42.3839 214.489C42.3839 213.644 40.5552 212.96 38.2994 212.96C36.0436 212.96 34.215 213.644 34.215 214.489C34.215 215.333 36.0436 216.018 38.2994 216.018Z"
        fill="#424761"
      />
      <path
        d="M48.1021 215.814C50.3579 215.814 52.1865 215.13 52.1865 214.285C52.1865 213.441 50.3579 212.756 48.1021 212.756C45.8463 212.756 44.0176 213.441 44.0176 214.285C44.0176 215.13 45.8463 215.814 48.1021 215.814Z"
        fill="#424761"
      />
      <path
        d="M41.9755 194.407C45.0208 194.407 47.4895 191.942 47.4895 188.902C47.4895 185.862 45.0208 183.397 41.9755 183.397C38.9301 183.397 36.4614 185.862 36.4614 188.902C36.4614 191.942 38.9301 194.407 41.9755 194.407Z"
        fill="white"
      />
      <path
        d="M41.9755 190.737C42.9906 190.737 43.8135 189.915 43.8135 188.902C43.8135 187.888 42.9906 187.067 41.9755 187.067C40.9604 187.067 40.1375 187.888 40.1375 188.902C40.1375 189.915 40.9604 190.737 41.9755 190.737Z"
        fill="#424761"
      />
      <path
        d="M25.9167 178.001C24.614 172.176 28.7783 166.291 35.2179 164.855C41.6575 163.42 47.9339 166.978 49.2367 172.802C50.5394 178.627 46.2716 180.775 39.832 182.21C33.3924 183.646 27.2195 183.825 25.9167 178.001Z"
        fill="#FFB102"
      />
      <path
        d="M30.6837 188.957C31.2831 187.695 28.5057 185.128 24.4803 183.222C20.4549 181.316 16.7058 180.793 16.1065 182.055C15.5071 183.316 18.2845 185.884 22.3099 187.79C26.3353 189.696 30.0844 190.219 30.6837 188.957Z"
        fill="#424761"
      />
      <path
        d="M33.6422 198.79C33.6422 200.366 37.708 203.479 42.2196 203.479C46.7312 203.479 50.9613 199.041 50.9613 197.465C50.9613 195.888 46.7312 197.771 42.2196 197.771C37.708 197.771 33.6422 197.214 33.6422 198.79Z"
        fill="white"
      />
      <path
        d="M216.717 75.423C216.182 58.9078 204.851 45.8718 191.41 46.3062C161.496 47.1911 160.766 101.956 189.647 105.89L189.035 110.743L197.955 110.454L197.031 105.651C208.649 103.088 217.203 90.3959 216.717 75.423Z"
        fill="#FFB102"
      />
      <path
        d="M176.535 84.5797L173.165 84.6886C171.844 78.4191 171.635 71.9669 172.547 65.6252L175.917 65.5163C174.672 72.1547 174.878 78.5091 176.535 84.5797Z"
        fill="white"
      />
      <path
        d="M198.393 105.576L188.283 105.903L188.356 108.145L198.465 107.819L198.393 105.576Z"
        fill="#424761"
      />
      <path
        d="M169.03 191.454L168.012 190.978L193.041 106.243L194.058 106.719L169.03 191.454Z"
        fill="#424761"
      />
      <path
        d="M237.433 89.3886C243.545 74.0328 238.369 57.5652 225.871 52.6072C198.09 41.4978 175.523 91.4194 200.431 106.536L197.93 110.741L206.223 114.031L207.296 109.259C218.974 111.538 231.891 103.31 237.433 89.3886Z"
        fill="#743C7A"
      />
      <path
        d="M196.929 81.7712L193.796 80.5282C195.092 74.2534 197.48 68.2541 200.851 62.803L203.985 64.046C200.189 69.6366 197.837 75.545 196.929 81.7712Z"
        fill="white"
      />
      <path
        d="M199.175 106.004L198.345 108.089L207.745 111.818L208.575 109.733L199.175 106.004Z"
        fill="#424761"
      />
      <path
        d="M168.832 189.223L167.855 188.668L202.798 109.058L203.775 109.613L168.832 189.223Z"
        fill="#424761"
      />
      <path
        d="M47.4983 76.4901C40.8852 61.3425 25.5304 53.4114 13.2023 58.7755C-14.2652 70.6376 5.33504 121.793 33.6271 114.789L34.8558 119.524L43.0371 115.964L40.3995 111.843C50.2454 105.173 53.4938 90.2232 47.4983 76.4901Z"
        fill="#743C7A"
      />
      <path
        d="M13.5527 99.8276L10.4619 101.172C6.91298 95.8345 4.32936 89.9166 2.82849 83.6875L5.91923 82.3427C7.22091 88.9702 9.76539 94.7985 13.5527 99.8276Z"
        fill="white"
      />
      <path
        d="M41.6367 111.27L32.3645 115.304L33.2626 117.361L42.5348 113.327L41.6367 111.27Z"
        fill="#424761"
      />
      <path
        d="M63.0895 191.67C62.9449 191.706 62.7919 191.683 62.6643 191.606C62.5366 191.53 62.4448 191.405 62.4089 191.261L37.9812 113.989L39.0719 113.719L63.4996 190.991C63.5174 191.062 63.5209 191.137 63.5099 191.21C63.4989 191.282 63.4736 191.352 63.4355 191.415C63.3974 191.479 63.3473 191.534 63.2879 191.577C63.2285 191.621 63.1611 191.653 63.0895 191.67Z"
        fill="#424761"
      />
      <path
        d="M69.618 216.101H10.8034C10.7041 216.101 10.6088 216.062 10.5386 215.992C10.4683 215.922 10.4288 215.827 10.4288 215.728C10.4288 215.628 10.4683 215.533 10.5386 215.463C10.6088 215.393 10.7041 215.354 10.8034 215.354H69.618C69.7174 215.354 69.8127 215.393 69.8829 215.463C69.9532 215.533 69.9926 215.628 69.9926 215.728C69.9926 215.827 69.9532 215.922 69.8829 215.992C69.8127 216.062 69.7174 216.101 69.618 216.101Z"
        fill="#828595"
      />
      <path
        d="M219.089 216.101H160.275C160.175 216.101 160.08 216.062 160.01 215.992C159.94 215.922 159.9 215.827 159.9 215.728C159.9 215.628 159.94 215.533 160.01 215.463C160.08 215.393 160.175 215.354 160.275 215.354H219.089C219.189 215.354 219.284 215.393 219.354 215.463C219.425 215.533 219.464 215.628 219.464 215.728C219.464 215.827 219.425 215.922 219.354 215.992C219.284 216.062 219.189 216.101 219.089 216.101Z"
        fill="#828595"
      />
    </svg>
  );
};

export const PublishCelebrationIcon = React.memo(SvgComponent);
