import React from 'react';
import { SVGProps } from 'theme/icons';

interface IdeasForeverProps {
  colorMode: 'light' | 'dark';
}

const SvgComponent: React.FC<IdeasForeverProps & SVGProps> = ({
  colorMode,
  ...props
}) => {
  return (
    <svg width="120" height="121" viewBox="0 0 120 121" fill="none" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        opacity="0.6"
        d="M94.271 0H25.745L0.569668 42.2923C0.15476 42.9898 -0.0412903 43.7976 0.00725018 44.6095C0.0557906 45.4214 0.346654 46.1995 0.841661 46.8417L56.8401 119.44C57.214 119.925 57.6931 120.318 58.2407 120.589C58.7884 120.859 59.3902 121 60 121C60.6098 121 61.2116 120.859 61.7593 120.589C62.3069 120.318 62.786 119.925 63.1599 119.44L119.158 46.8417C119.653 46.1995 119.944 45.4214 119.993 44.6095C120.041 43.7976 119.845 42.9898 119.43 42.2923L94.271 0Z"
        fill={colorMode === 'light' ? '#FFB102' : '#996b00'}
      />
    </svg>
  );
};

export const IdeasForever = React.memo(SvgComponent);
