import { Icon, StyleProps } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps & StyleProps> = props => {
  return (
    <Icon
      width="184px"
      height="148px"
      viewBox="0 0 184 148"
      fill="none"
      {...props}
    >
      <path
        d="M23.9212 146C17.1538 105.722 9.44091 62.1149 2 21.9753L154.302 2L182 125.981L23.9212 146Z"
        fill="#E7E3DF"
        stroke="#E7E3DF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.4492 93.9154C93.5373 92.7131 96.9344 92.4768 100.167 93.2394C103.4 94.002 106.308 95.7254 108.485 98.1698C110.553 100.702 111.736 103.808 111.863 107.038C111.99 110.268 111.054 113.454 109.191 116.131C99.956 128.718 75.0882 118.699 81.4606 103.544C83.2285 99.353 86.1039 95.7028 90.4492 93.9154Z"
        fill="#F9A725"
        stroke="#F9A725"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.0747 109.045L89.6068 105.772C90.4288 104.577 91.4536 103.526 92.6376 102.663C93.2354 102.238 93.9332 101.966 94.6673 101.87C95.4015 101.774 96.1486 101.859 96.8405 102.116C97.0413 103.498 96.8992 104.907 96.426 106.226C97.0038 105.437 97.7002 104.737 98.4919 104.149C99.1286 103.707 99.8877 103.462 100.67 103.446C101.452 103.43 102.221 103.645 102.876 104.061C104.126 104.979 104.379 107.044 103.18 108.019"
        stroke="#F87D21"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9824 113.205L99.412 113.022"
        stroke="#F87D21"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.917 110.713C132.813 108.498 134.792 106.352 136.856 104.275C137.335 103.784 137.944 103.261 138.63 103.368C139.44 103.488 139.828 104.394 139.958 105.181C140.087 105.968 140.133 106.861 140.742 107.39C144.106 105.102 147.619 103.027 151.259 101.178C152.036 100.69 152.959 100.469 153.881 100.549C155.487 100.857 156.116 102.726 156.439 104.287"
        stroke="#323130"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6782 121.481C38.3893 116.527 43.1059 112.365 48.5368 109.253C49.1463 111.472 49.5793 113.734 49.832 116.018C52.6933 112.681 56.4877 110.219 60.757 108.932C61.0549 108.837 61.4046 108.774 61.6442 109.007C61.7566 109.165 61.8072 109.357 61.7867 109.549L61.8385 113.746"
        stroke="#323130"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.355 48.9428C52.8867 43.0351 58.7374 38.2024 65.4673 34.808C72.1973 31.4137 79.6314 29.5459 87.2109 29.3451C90.2069 29.3134 93.2022 29.4585 96.1801 29.7794C98.9578 30.0216 101.72 30.406 104.456 30.931C111.377 32.3353 117.931 35.0909 123.722 39.0307"
        stroke="#68366E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.8574 61.5988L84.3874 57.9927"
        stroke="#323130"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9175 55.922L110.848 53.6627"
        stroke="#323130"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.4">
        <path
          opacity="0.4"
          d="M81.1045 70.7305C86.5055 69.8368 91.9194 69.025 97.3463 68.2949L96.6987 68.5341"
          stroke="#323130"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M76.4224 76.6337C85.152 74.8967 93.9507 73.5437 102.818 72.5745"
          stroke="#323130"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.4"
          d="M78.8317 81.4923L78.1323 81.6245C86.7389 80.542 95.3002 79.1071 103.764 77.2191"
          stroke="#323130"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Icon>
  );
};

export const AboutYourselfIllustration = React.memo(SvgComponent);
