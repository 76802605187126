import { Icon, StyleProps } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps & StyleProps> = props => {
  return (
    <Icon width="62px" height="84px" viewBox="0 0 62 84" fill="none" {...props}>
      <path
        d="M5.32227 48.2041L5.92874 74.1575C5.97488 76.2604 6.10013 78.5677 7.51086 80.1366C9.32371 82.1538 12.4418 82.1143 15.1446 81.9033C26.2986 81.0244 37.5164 81.9033 48.4159 81.4096C49.6156 81.2316 52.9558 81.4419 53.8656 80.6311C55.1115 79.517 54.8388 77.517 54.4802 72.0673C53.8656 62.7249 54.1023 54.025 54.4802 44.875"
        stroke="#323130"
        strokeWidth="2.11687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.70312 47.1551L3.26018 36.2557L30.5087 33.1416L59.3143 33.9201L58.5358 44.041L11.0455 47.1551H1.70312Z"
        fill="#FADAD8"
      />
      <path
        d="M58.1049 44.4399C59.5273 41.0614 59.9483 37.3452 59.3178 33.7342C40.5093 31.7116 21.5045 32.5003 2.92826 36.0744C2.61184 36.1337 2.19653 38.7113 2.13061 39.0672C1.93324 40.1294 1.81425 41.2046 1.77463 42.2842C1.72189 43.662 1.44501 46.5098 2.50635 47.6041C3.0601 48.1711 3.89731 47.9733 4.59608 47.8942L8.04381 47.5316C10.3445 47.2943 12.6583 47.0768 14.9458 46.8724C24.1485 46.033 33.3666 45.4001 42.6001 44.9738C47.7683 44.7365 52.9366 44.5585 58.1049 44.4399Z"
        stroke="#323130"
        strokeWidth="2.11687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06055 27.3595C15.5646 27.2343 22.0269 28.4249 28.0592 30.8599C24.1935 29.1003 20.9602 26.1979 18.7949 22.5439C16.6296 18.8899 15.6365 14.6599 15.9494 10.4241C16.1735 8.0707 16.8591 5.66456 18.4808 3.95059C20.1025 2.23662 22.8119 1.406 24.9082 2.48053C27.8747 3.99014 28.2043 8.03774 27.9867 11.3536C27.5536 18.5961 26.5997 25.798 25.1323 32.9035C26.5101 23.8722 30.1226 14.8739 36.8796 8.72334C38.2969 7.43786 39.9186 6.25126 41.804 5.94802C43.6893 5.64478 45.8713 6.4688 46.5899 8.2421C47.5062 10.5164 45.7461 12.9292 44.0651 14.7222C38.439 20.7096 32.3438 26.2382 25.8377 31.2554C35.9868 24.5539 48.3156 21.9901 60.2951 24.0897"
        stroke="#323130"
        strokeWidth="2.11687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.6387 62.1725L32.4424 59.8125"
        stroke="#323130"
        strokeWidth="5.08049"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export const CuratorGift = React.memo(SvgComponent);
