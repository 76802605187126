import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const BookReversedDrawing = React.memo<IconProps>(props => {
  return (
    <Icon
      width={'62px'}
      height={'45px'}
      fill="none"
      viewBox="0 0 62 45"
      {...props}
    >
      <path
        d="M38.972 38.387C45.95 29.037 53.534 19.034 61 10.09c-.036-1.911-.38-4.411-.558-6.313a230.173 230.173 0 0 0-18.688-2.549C34.977.586 29.14 1.078 23.77 5.565A170.55 170.55 0 0 0 3.32 25.842a10.506 10.506 0 0 0-1.82 2.664c-.699 1.668-.52 3.558-.326 5.357.167 1.566 2.233 2.749 3.738 3.216a195.365 195.365 0 0 0 28.948 6.629c.277.065.566.065.843 0a1.82 1.82 0 0 0 .85-.831c.989-1.535 2.333-3.031 3.419-4.49Z"
        stroke="#323130"
        strokeWidth={props.strokeWidth ?? 1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.032 4.532a460.565 460.565 0 0 0-25.69 31.133 2.488 2.488 0 0 1-2.478.964L5.576 30.304c-.852-.203-1.732-.41-2.596-.267-.865.143-1.718.722-1.9 1.578M33.614 43.2c-.179-1.757-.303-6.401-.303-6.401M32.146 9.027c2.427-.16 7.663.953 10.042 1.456M32.756 11.961c1.41-.082 6.189.686 6.189.686M33.614 40.765c4.948-7.408 10.518-14.18 16.08-21.142M54.875 14.443a144.549 144.549 0 0 1 4.496-5.518"
        stroke="#323130"
        strokeWidth={props.strokeWidth ?? 1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.067 33.036c2.91-.968 6.068-.07 9.008.828 1.322.404 11.036 2.822 11.036 2.822M8.377 35.503c7.447 1.72 17.705 4.047 25.253 5.26"
        stroke="#323130"
        strokeWidth={props.strokeWidth ?? 1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});

export default BookReversedDrawing;
