import { Icon } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps & { color?: string }> = props => {
  return (
    <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12 3.667C7.405 3.667 3.667 7.405 3.667 12C3.667 16.595 7.405 20.333 12 20.333C16.595 20.333 20.333 16.595 20.333 12C20.333 7.405 16.595 3.667 12 3.667ZM11.25 6C11.6295 6 11.9435 6.28233 11.9931 6.64827L12 6.75V12H15.25C15.664 12 16 12.336 16 12.75C16 13.1295 15.7177 13.4435 15.3517 13.4931L15.25 13.5H11.25C10.8705 13.5 10.5565 13.2177 10.5069 12.8517L10.5 12.75V6.75C10.5 6.336 10.836 6 11.25 6Z"
        fill={props.color ?? '#212121'}
      />
    </Icon>
  );
};

export const ClockIconSvg = React.memo(SvgComponent);
