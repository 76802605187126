import { useColorMode } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  const { colorMode } = useColorMode();
  //LIGHT and DARK versions of this SVG
  const themeColors = {
    light: {
      torso: '#743C7A',
      pants: '#292D3D',
      hair: '#47104E',
      bigPlanetAndShadow: '#10121F',
      smallPlanet: '#FFFFFF',
    },
    dark: {
      torso: '#B786BB',
      pants: '#494D5D',
      hair: '#743C7A',
      bigPlanetAndShadow: '#999AA3',
      smallPlanet: '#1C1F2E',
    },
    skin: '#FFB9B9',
  };
  return (
    <svg width={174} height={182} viewBox="0 0 174 182" fill="none" {...props}>
      <path
        d="M33.9017 181.451C52.6251 181.451 67.8034 179.479 67.8034 177.045C67.8034 174.612 52.6251 172.64 33.9017 172.64C15.1783 172.64 0 174.612 0 177.045C0 179.479 15.1783 181.451 33.9017 181.451Z"
        fill={themeColors[colorMode].bigPlanetAndShadow}
      />
      <path
        d="M17.8883 55.0822L16.7622 57.7605L18.0291 75.0989C18.0291 75.0989 17.3253 76.0857 18.0291 76.7905C18.7329 77.4953 18.5922 77.7773 18.5922 77.7773C18.5922 77.7773 17.466 77.7773 18.0291 78.623C18.5922 79.4688 18.5922 79.8917 18.5922 79.8917L18.3106 80.7375C18.3106 80.7375 18.8737 81.4423 18.8737 82.0062C18.8737 82.57 22.5337 99.9085 22.5337 99.9085L26.0528 93.0013L24.0821 65.5134L17.8883 55.0822Z"
        fill={themeColors[colorMode].torso}
      />
      <path
        d="M54.9178 98.4532C54.9178 98.4532 61.1112 103.021 61.3294 106.674C61.5475 110.328 53.3457 101.234 53.2899 100.21C53.2342 99.185 54.9178 98.4532 54.9178 98.4532Z"
        fill={themeColors.skin}
      />
      <path
        d="M16.0586 167.571L14.5102 172.646C14.5102 172.646 12.2579 177.579 15.3548 178.284C18.4516 178.989 21.4077 176.311 21.4077 176.311V172.928L22.5339 167.571H16.0586Z"
        fill={themeColors.skin}
      />
      <path
        d="M41.3964 167.571C41.3964 167.571 40.6926 177.156 42.241 177.72C43.7895 178.284 49.2794 178.425 49.4202 177.72C49.5609 177.015 51.2501 176.311 49.9832 175.324C48.7163 174.337 47.8717 168.558 47.8717 168.558L41.3964 167.571Z"
        fill={themeColors.skin}
      />
      <path
        d="M27.1793 76.7905V81.0194C27.1793 81.0194 22.534 90.7459 20.8448 106.111C19.1556 121.476 13.3842 168.276 14.7918 169.122C16.1995 169.967 31.4024 170.531 31.8246 169.403C32.2469 168.276 32.6692 135.29 32.6692 135.29L37.8776 112.031C37.8776 112.031 38.863 120.348 39.7076 121.758C40.5522 123.167 38.2999 168.981 39.7076 169.685C41.1153 170.39 60.1188 171.659 59.8373 169.403C59.5558 167.148 53.2212 105.97 53.2212 105.97C53.2212 105.97 49.9836 77.0724 48.9982 76.7905C48.0129 76.5086 39.0038 76.2266 37.5961 76.5086C36.1884 76.7905 27.1793 76.7905 27.1793 76.7905Z"
        fill={themeColors[colorMode].pants}
      />
      <path
        d="M37.8772 41.8316C42.3086 41.8316 45.901 38.2342 45.901 33.7967C45.901 29.3591 42.3086 25.7617 37.8772 25.7617C33.4459 25.7617 29.8535 29.3591 29.8535 33.7967C29.8535 38.2342 33.4459 41.8316 37.8772 41.8316Z"
        fill={themeColors.skin}
      />
      <path
        d="M39.5669 40.5629C39.5669 40.5629 35.4846 46.9063 36.47 48.5978C37.4554 50.2894 26.0532 46.9063 26.0532 46.9063C26.0532 46.9063 32.9508 38.4485 32.81 37.4617C32.6693 36.475 39.5669 40.5629 39.5669 40.5629Z"
        fill={themeColors.skin}
      />
      <path
        d="M48.0125 50.0075C48.0125 50.0075 40.5518 47.6111 39.5665 46.6243C38.9344 45.9777 38.3685 45.2694 37.8773 44.5099C37.8773 44.5099 28.8682 43.2412 28.5866 43.2412C28.3051 43.2412 26.1936 45.7785 25.6305 46.3424C25.0675 46.9063 17.8883 50.7123 17.8883 51.699C17.8883 52.6858 16.7622 57.7604 16.7622 57.7604C16.7622 57.7604 19.296 62.9761 20.8445 64.1038C22.3929 65.2315 22.8152 64.9496 22.956 66.7821C23.0967 68.6146 25.0675 71.5749 25.0675 71.5749C25.0675 71.5749 26.3344 74.817 26.0528 75.8038C25.7713 76.7905 24.5044 77.6363 27.4605 77.3544C30.4166 77.0724 45.0564 77.9182 45.0564 76.3676C45.0564 74.817 42.2411 60.0159 42.2411 60.0159L48.0125 50.0075Z"
        fill={themeColors[colorMode].torso}
      />
      <path
        d="M45.338 50.2894L48.0125 50.0074C48.0125 50.0074 48.9979 50.5713 49.1387 56.0689C49.2794 61.5664 49.2794 74.817 50.2648 75.9447C51.2502 77.0724 55.3324 94.5519 55.3324 94.5519C55.3324 94.5519 56.4586 94.8338 56.4586 95.8206C56.4586 96.8073 57.7255 97.6531 57.1624 98.7808C56.5993 99.9085 57.7255 99.6266 57.0216 100.331C56.3178 101.036 52.9394 102.446 52.7986 101.6C52.6579 100.754 53.2209 100.613 52.6579 100.331C52.0948 100.049 52.0948 99.9085 51.954 98.9217C51.8133 97.935 50.5463 96.9483 50.6871 96.5254C50.8279 96.1025 44.4934 83.8387 44.4934 83.4158C44.4934 82.9929 44.0711 81.4423 43.508 81.1604C42.9449 80.8785 42.1003 80.5965 42.5226 79.8917C42.9449 79.1869 41.3965 58.0424 41.3965 58.0424L45.338 50.2894Z"
        fill={themeColors[colorMode].torso}
      />
      <path
        d="M44.6625 28.701C42.202 26.0265 38.338 24.6069 34.7946 25.4024C31.2511 26.198 28.2673 29.3484 27.9902 32.9743C27.9335 33.716 27.9807 34.4736 27.7891 35.1922C27.4667 36.4011 26.5075 37.3591 26.1694 38.5636C25.9429 39.3703 26.01 40.2389 25.77 41.0417C25.3264 42.525 23.9535 43.4922 22.9072 44.6322C22.4834 45.0501 22.1687 45.5658 21.9908 46.134C21.8987 46.6379 21.8754 47.152 21.9214 47.6621C21.9655 50.412 20.9446 53.0722 19.0728 55.0848C20.1734 54.2659 21.3625 53.5737 22.6176 53.021C21.9955 55.005 22.5643 57.3523 24.1297 58.7187C25.695 60.085 28.2023 60.2973 29.8948 59.0925C30.9003 58.3768 31.5534 57.2784 32.1771 56.2126L34.5388 52.1774C34.966 51.4475 35.412 50.6963 36.0945 50.1978C36.637 49.8016 37.296 49.5908 37.842 49.1996C39.2194 48.2127 39.5892 46.2812 39.3327 44.6048C39.0763 42.9284 38.3234 41.3719 37.8917 39.732C37.5502 38.4349 37.443 36.9485 38.2112 35.8497C39.231 34.3911 41.3268 34.205 42.7125 33.0893C43.2342 32.6692 43.4673 32.1582 43.8409 31.6324C44.1859 31.147 44.2738 31.321 44.8083 31.2988C46.2759 31.2379 45.2063 29.2922 44.6625 28.701Z"
        fill={themeColors[colorMode].hair}
      />
      <path
        d="M37.6543 38.1399C38.0116 38.1399 38.3013 37.4437 38.3013 36.5849C38.3013 35.7262 38.0116 35.03 37.6543 35.03C37.297 35.03 37.0073 35.7262 37.0073 36.5849C37.0073 37.4437 37.297 38.1399 37.6543 38.1399Z"
        fill={themeColors.skin}
      />
      <path
        d="M139.438 134.859C143.387 134.859 146.589 131.653 146.589 127.698C146.589 123.744 143.387 120.538 139.438 120.538C135.489 120.538 132.288 123.744 132.288 127.698C132.288 131.653 135.489 134.859 139.438 134.859Z"
        fill={themeColors[colorMode].smallPlanet}
      />
      <path
        d="M69.7194 16.7082C74.3268 16.7082 78.0619 12.9679 78.0619 8.3541C78.0619 3.74026 74.3268 0 69.7194 0C65.112 0 61.377 3.74026 61.377 8.3541C61.377 12.9679 65.112 16.7082 69.7194 16.7082Z"
        fill={themeColors[colorMode].smallPlanet}
      />
      <path
        d="M168.041 22.6754C171.332 22.6754 174 20.0038 174 16.7082C174 13.4126 171.332 10.741 168.041 10.741C164.75 10.741 162.082 13.4126 162.082 16.7082C162.082 20.0038 164.75 22.6754 168.041 22.6754Z"
        fill={themeColors[colorMode].smallPlanet}
      />
      <ellipse
        cx="108.452"
        cy="62.6557"
        rx="39.3288"
        ry="39.3836"
        fill={themeColors[colorMode].bigPlanetAndShadow}
      />
    </svg>
  );
};

export const NoResultsSvg = React.memo(SvgComponent);
