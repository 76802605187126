import { Icon } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';
import { AI_COLOR } from 'utils/constants';

const SvgComponent: React.FC<SVGProps & { color?: string }> = props => {
  return (
    <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.87609 11.5642C6.98377 11.1023 7.64123 11.1023 7.74891 11.5642L8.30291 13.9403C8.34068 14.1023 8.46507 14.23 8.62599 14.2721L10.9664 14.8838C11.4128 15.0005 11.4128 15.6342 10.9664 15.7509L8.62599 16.3627C8.46507 16.4047 8.34068 16.5325 8.30291 16.6945L7.74891 19.0706C7.64123 19.5324 6.98377 19.5324 6.87609 19.0706L6.32209 16.6945C6.28432 16.5325 6.15993 16.4047 5.99901 16.3627L3.65863 15.7509C3.21225 15.6343 3.21224 15.0005 3.65863 14.8838L5.99901 14.2721C6.15993 14.23 6.28432 14.1023 6.32209 13.9403L6.87609 11.5642Z"
        fill={AI_COLOR}
      />
      <path
        d="M14.3767 2.82118C14.4864 2.36275 15.1386 2.36275 15.2483 2.82118L16.1726 6.68355C16.2113 6.84539 16.3365 6.97244 16.4978 7.01351L20.2949 7.98052C20.7449 8.09513 20.7449 8.73441 20.2949 8.84902L16.4978 9.81604C16.3365 9.85711 16.2113 9.98416 16.1726 10.146L15.2483 14.0084C15.1386 14.4668 14.4864 14.4668 14.3767 14.0084L13.4524 10.146C13.4137 9.98416 13.2885 9.85711 13.1272 9.81604L9.33014 8.84903C8.88011 8.73442 8.88011 8.09513 9.33013 7.98052L13.1272 7.01351C13.2885 6.97244 13.4137 6.84539 13.4524 6.68354L14.3767 2.82118Z"
        fill={AI_COLOR}
      />
      <path
        d="M11.2533 18.5532C11.3681 18.1035 12.0069 18.1035 12.1217 18.5532L12.3012 19.2561C12.3423 19.4174 12.4694 19.5425 12.6313 19.5811L13.299 19.7405C13.7578 19.85 13.7578 20.5028 13.299 20.6123L12.6313 20.7717C12.4694 20.8103 12.3423 20.9354 12.3012 21.0967L12.1217 21.7996C12.0069 22.2493 11.3681 22.2493 11.2533 21.7996L11.0738 21.0967C11.0327 20.9354 10.9056 20.8103 10.7437 20.7717L10.076 20.6123C9.6172 20.5028 9.6172 19.85 10.076 19.7405L10.7437 19.5811C10.9056 19.5425 11.0327 19.4174 11.0738 19.2561L11.2533 18.5532Z"
        fill={AI_COLOR}
      />
    </Icon>
  );
};

export const EnhanceSvg = React.memo(SvgComponent);
