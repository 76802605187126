import { StyleProps, Icon } from 'deepstash-ui';
import React from 'react';
import { color } from 'deepstash-ui';

export const Pages: React.FC<StyleProps> = props => {
  return (
    <Icon viewBox="0 0 8 116" {...props}>
      <mask id="prefix__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 116h3a5 5 0 005-5V5a5 5 0 00-5-5H0a4 4 0 014 4v108a4 4 0 01-4 4z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 116h3a5 5 0 005-5V5a5 5 0 00-5-5H0a4 4 0 014 4v108a4 4 0 01-4 4z"
        fill="#fff"
      />
      <path
        d="M3 115H0v2h3v-2zm4-4a4 4 0 01-4 4v2a6 6 0 006-6H7zM7 5v106h2V5H7zM3 1a4 4 0 014 4h2a6 6 0 00-6-6v2zM0 1h3v-2H0v2zm0 0a3 3 0 013 3h2a5 5 0 00-5-5v2zm3 3v108h2V4H3zm0 108a3 3 0 01-3 3v2a5 5 0 005-5H3z"
        fill={color.light.textSecondary}
        mask="url(#prefix__a)"
      />
      <path
        d="M6 5v106"
        stroke={color.light.textSecondary}
        strokeWidth={0.8}
        strokeLinecap="round"
      />
    </Icon>
  );
};
