import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 27 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43955 23.9184C5.30955 24.6567 6.03953 25.235 6.68284 24.905H6.68117L13.9959 21.1451L21.314 24.905C21.9573 25.235 22.6873 24.6567 22.5573 23.9184L21.174 16.0353L27.0455 10.4422C27.5938 9.91884 27.3088 8.9622 26.5738 8.85887L18.4108 7.69891L14.7709 0.487484C14.4426 -0.162495 13.5543 -0.162495 13.226 0.487484L9.58608 7.69891L1.42301 8.85887C0.688038 8.9622 0.403048 9.91884 0.951363 10.4422L6.82283 16.0353L5.43955 23.9184ZM23.9682 11.3604C24.4814 10.7867 24.181 9.8725 23.4275 9.71494L19.8366 8.9641C19.0831 8.80655 18.4416 9.52377 18.6819 10.2551L19.8271 13.7403C20.0674 14.4717 21.0093 14.6686 21.5225 14.0948L23.9682 11.3604Z"
        fill="#FFB102"
      />
    </svg>
  );
};

export const PartialStar = React.memo(SvgComponent);
