import { Icon, StyleProps } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps & StyleProps> = props => {
  return (
    <Icon width="46px" height="82px" viewBox="0 0 46 82" fill="none" {...props}>
      <path
        d="M12.6344 50.0836C12.7789 50.7969 12.9004 51.3675 12.9867 51.748C13.2171 52.7265 13.6126 53.6586 14.1561 54.5043C15.1002 55.9467 16.6342 57.2448 18.5081 57.4526C22.9253 57.9387 27.7158 56.9242 31.8353 55.8199C33.3148 55.4237 37.6474 53.8174 39.5671 52.9896C43.308 51.3764 42.371 46.6492 42.0716 43.317C41.7792 40.0429 41.1135 36.7459 40.5006 33.5175C39.0528 25.8844 37.2934 18.3146 35.8721 10.6744C35.3824 8.00442 34.1654 3.51858 32.2351 2.07965C31.3316 1.40863 28.7549 0.9965 27.4428 1.00002C22.4357 1.01587 18.3461 1.4509 13.353 1.88064C9.54878 2.21351 5.42751 2.69432 5.79209 7.20834C6.14433 11.666 7.25743 19.9579 8.75623 28.5562C9.04859 30.2328 9.58224 33.3115 10.1811 36.7177"
        stroke="#323130"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8178 40.6098C43.1123 38.6143 44.4473 35.7523 43.9506 33.7286C43.5544 32.1171 42.4043 31.1995 40.3436 32.0801C43.9506 29.4788 42.4043 24.7904 39.2746 25.6006C41.6822 23.8147 41.3317 19.2144 37.7793 20.169"
        stroke="#323130"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5273 54.9336C33.8228 56.3021 32.6022 58.2975 32.176 59.7946C31.6283 61.7126 31.8079 64.2787 31.9013 66.2424C32.0263 68.8843 32.2535 80.9997 32.2535 80.9997"
        stroke="#323130"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51029 6.28557L9.44336 5.76953"
        stroke="#A19F9D"
        strokeOpacity="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0625 6.0848L10.9551 5.25879"
        stroke="#A19F9D"
        strokeOpacity="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6185 5.88434L12.4512 4.58984"
        stroke="#A19F9D"
        strokeOpacity="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.457 4.3777C28.3922 4.3231 29.9879 4.29492 29.9879 4.29492"
        stroke="#A19F9D"
        strokeOpacity="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16716 77.8424C2.16716 72.4108 2.01393 66.9809 1.94876 61.5493C1.88888 56.639 2.17949 51.3465 3.37888 46.5419C4.1908 43.2925 6.79566 38.9792 9.61362 37.0965C11.2815 35.9816 15.29 33.8981 17.3771 35.1045C20.1 36.6791 17.9301 40.64 16.8663 42.3942C14.8568 45.7159 12.4633 49.5483 11.6566 52.9404"
        stroke="#323130"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9917 17.3489C15.5152 17.4421 15.2044 17.9039 15.2976 18.3804L16.2252 23.1258C16.5189 24.6281 17.3045 25.8317 18.4326 26.5793C19.3304 27.1742 20.4042 27.4532 21.5391 27.3978L21.6776 28.1064C22.2598 31.0846 25.146 33.0269 28.1242 32.4447C31.1024 31.8626 33.0447 28.9763 32.4625 25.9982L32.3518 25.4315C33.4071 24.8474 34.0152 23.6308 33.7713 22.3832C33.4686 20.8345 31.9678 19.8245 30.4191 20.1273C28.8705 20.43 27.8605 21.9308 28.1632 23.4795C28.4071 24.7271 29.4286 25.6251 30.6262 25.7688L30.737 26.3355C31.1328 28.3606 29.812 30.3233 27.7869 30.7191C25.7617 31.115 23.7991 29.7942 23.4032 27.7691L23.2647 27.0605C24.3369 26.6844 25.2266 26.0216 25.8343 25.1324C26.5978 24.0151 26.8724 22.6042 26.5787 21.1019L25.6511 16.3565C25.5579 15.8799 25.0961 15.5692 24.6196 15.6623L22.896 15.9993C22.4195 16.0924 22.1087 16.5542 22.2019 17.0307C22.295 17.5072 22.7568 17.818 23.2333 17.7248L24.0941 17.5566L24.8531 21.4392C25.0654 22.5252 24.8555 23.4484 24.3826 24.1404C23.9082 24.8345 23.1254 25.3599 22.0766 25.5649C21.0278 25.77 20.1047 25.578 19.4038 25.1136C18.7052 24.6507 18.1631 23.8745 17.9508 22.7885L17.1918 18.9058L18.0546 18.7372C18.5311 18.644 18.8419 18.1822 18.7488 17.7057C18.6556 17.2292 18.1938 16.9184 17.7173 17.0116L15.9917 17.3489ZM30.7564 21.8528C31.3521 21.7364 31.9293 22.1249 32.0458 22.7205C32.1622 23.3161 31.7737 23.8934 31.1781 24.0098C30.5825 24.1263 30.0052 23.7378 29.8888 23.1422C29.7723 22.5465 30.1608 21.9693 30.7564 21.8528Z"
        fill="#743C7A"
      />
    </Icon>
  );
};

export const SupportPhone = React.memo(SvgComponent);
