import { Flex, useColorMode, color, PrimaryText, spacing } from 'deepstash-ui';
import React from 'react';
import { SVGProps } from 'theme/icons';
import { NoResultsSvg } from './NoResultsSvg';

const SvgComponent: React.FC<SVGProps> = props => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      flexDirection="column"
    >
      <NoResultsSvg width={174} height={182} {...props} />
      <PrimaryText
        size="m"
        type="semiBold"
        color={color[colorMode].textDisabled}
        mt={spacing.toRem(32)}
        textAlign="center"
      >
        No results found. Try something else?
      </PrimaryText>
    </Flex>
  );
};

export const NoResultsIcon = React.memo(SvgComponent);
