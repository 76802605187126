import * as React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg
      width="136"
      height="24"
      viewBox="0 0 136 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.41588 23.9088C4.83689 24.2256 4.1799 23.6704 4.2969 22.9616L5.54187 15.3939L0.257475 10.0245C-0.236015 9.52208 0.0204797 8.60371 0.681967 8.50452L8.02883 7.39095L11.3048 0.467984C11.6003 -0.155995 12.3997 -0.155995 12.6952 0.467984L15.9712 7.39095L23.318 8.50452C23.9795 8.60371 24.236 9.52208 23.7425 10.0245L18.4581 15.3939L19.7031 22.9616C19.8201 23.6704 19.1631 24.2256 18.5841 23.9088L11.9978 20.2993L5.41438 23.9088H5.41588Z"
        fill="#FFB102"
      />
      <path
        d="M33.4159 23.9088C32.8369 24.2256 32.1799 23.6704 32.2969 22.9616L33.5419 15.3939L28.2575 10.0245C27.764 9.52208 28.0205 8.60371 28.682 8.50452L36.0288 7.39095L39.3048 0.467984C39.6003 -0.155995 40.3997 -0.155995 40.6952 0.467984L43.9712 7.39095L51.318 8.50452C51.9795 8.60371 52.236 9.52208 51.7425 10.0245L46.4581 15.3939L47.7031 22.9616C47.8201 23.6704 47.1631 24.2256 46.5841 23.9088L39.9978 20.2993L33.4144 23.9088H33.4159Z"
        fill="#FFB102"
      />
      <path
        d="M61.4159 23.9088C60.8369 24.2256 60.1799 23.6704 60.2969 22.9616L61.5419 15.3939L56.2575 10.0245C55.764 9.52208 56.0205 8.60371 56.682 8.50452L64.0288 7.39095L67.3048 0.467984C67.6003 -0.155995 68.3997 -0.155995 68.6952 0.467984L71.9712 7.39095L79.318 8.50452C79.9795 8.60371 80.236 9.52208 79.7425 10.0245L74.4581 15.3939L75.7031 22.9616C75.8201 23.6704 75.1631 24.2256 74.5841 23.9088L67.9978 20.2993L61.4144 23.9088H61.4159Z"
        fill="#FFB102"
      />
      <path
        d="M89.4159 23.9088C88.8369 24.2256 88.1799 23.6704 88.2969 22.9616L89.5419 15.3939L84.2575 10.0245C83.764 9.52208 84.0205 8.60371 84.682 8.50452L92.0288 7.39095L95.3048 0.467984C95.6003 -0.155995 96.3997 -0.155995 96.6952 0.467984L99.9712 7.39095L107.318 8.50452C107.98 8.60371 108.236 9.52208 107.743 10.0245L102.458 15.3939L103.703 22.9616C103.82 23.6704 103.163 24.2256 102.584 23.9088L95.9978 20.2993L89.4144 23.9088H89.4159Z"
        fill="#FFB102"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.297 22.9616C116.18 23.6704 116.837 24.2256 117.416 23.9088H117.414L123.998 20.2993L130.584 23.9088C131.163 24.2256 131.82 23.6704 131.703 22.9616L130.458 15.3939L135.743 10.0245C136.236 9.52208 135.98 8.60371 135.318 8.50452L127.971 7.39095L124.695 0.467984C124.4 -0.155995 123.6 -0.155995 123.305 0.467984L120.029 7.39095L112.682 8.50452C112.02 8.60371 111.764 9.52208 112.257 10.0245L117.542 15.3939L116.297 22.9616ZM132.936 10.9479C133.426 10.3638 133.152 9.47524 132.433 9.31484L129.433 8.64574C128.685 8.47887 128.021 9.21953 128.25 9.96492L129.207 13.0707C129.437 13.8161 130.383 13.9924 130.893 13.3847L132.936 10.9479Z"
        fill="#FFB102"
      />
    </svg>
  );
};

export const Reviews = React.memo(SvgComponent);
