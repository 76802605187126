import React from 'react';
import { SVGProps } from 'theme/icons';

const SvgComponent: React.FC<SVGProps> = props => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
      <path
        d="M22.3214 0H2.67857C1.19978 0 0 1.19978 0 2.67857V22.3214C0 23.8002 1.19978 25 2.67857 25H22.3214C23.8002 25 25 23.8002 25 22.3214V2.67857C25 1.19978 23.8002 0 22.3214 0ZM7.08705 19.6708C6.78013 20.2065 6.09375 20.385 5.56362 20.0781C5.0279 19.7712 4.84933 19.0848 5.15625 18.5547L5.95424 17.1763C6.85268 16.9029 7.58929 17.115 8.16406 17.8125L7.08705 19.6708ZM14.8382 16.6629H4.6875C4.07366 16.6629 3.57143 16.1607 3.57143 15.5469C3.57143 14.933 4.07366 14.4308 4.6875 14.4308H7.53348L11.183 8.11384L10.0391 6.13839C9.73214 5.60268 9.91629 4.92188 10.4464 4.61495C10.9821 4.30804 11.6629 4.49219 11.9699 5.02232L12.4665 5.8817L12.9632 5.02232C13.2701 4.48661 13.9565 4.30804 14.4866 4.61495C15.0223 4.92188 15.2009 5.60826 14.894 6.13839L10.106 14.4308H13.5714C14.6987 14.4308 15.3292 15.7533 14.8382 16.6629ZM20.3125 16.6629H18.6942L19.7879 18.5547C20.0949 19.0904 19.9107 19.7712 19.3806 20.0781C18.8449 20.385 18.1641 20.2009 17.8571 19.6708C16.0212 16.4955 14.6484 14.1071 13.7277 12.5223C12.7958 10.904 13.4598 9.28571 14.1239 8.73884C14.8549 10.0056 15.9487 11.9029 17.4107 14.4308H20.3125C20.9263 14.4308 21.4286 14.933 21.4286 15.5469C21.4286 16.1663 20.9263 16.6629 20.3125 16.6629Z"
        fill="#3E5774"
      />
    </svg>
  );
};

export const AppStore = React.memo(SvgComponent);
